const menuBtn = document.querySelector('.js-navigation-toggle');
const menuWrapper = document.querySelector('.navigation');

const events = () => {
  menuBtn.addEventListener('click', () => {
    menuBtn.classList.toggle('is--open');
    menuWrapper.classList.toggle('nav--open');
  });
};

const init = () => {
  if (menuWrapper) {
    events();
  }
};

export default {
  init,
};
