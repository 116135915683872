import * as Sentry from '@sentry/browser';
import Components from './components';

import '../css/main.scss';

const isProduction = process.env.NODE_ENV === 'production';
if (isProduction) {
  Sentry.init({
    dsn: 'https://dac420d5f2914449a67449de179daa0f@sentry.yournext.agency/25',
  });
}

if (Components.length) {
  Components.forEach((component) => component.init());
}
