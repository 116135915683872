const init = () => {
  const boxFilter = document.querySelector('.faq-list__question');
  if (boxFilter) {
    document.querySelectorAll('.faq-list__question')
      .forEach((link) => {
        link.addEventListener('click', (e) => {
          e.preventDefault();
          link.classList.toggle('is--active');
          link.nextElementSibling.classList.toggle('is--closed');
        });
      });
  }
};

export default {
  init,
};
