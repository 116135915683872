// import Swiper JS
import Swiper, {
  Navigation,
  Pagination,
  Thumbs,
  EffectFade,
} from 'swiper';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Thumbs, EffectFade]);

let swiperContainer;

const init = () => {
  swiperContainer = document.querySelector('.swiper-container');
  if (swiperContainer) {
    const swiperIndustriesTabs = new Swiper('.js-facts-slider-pagination', {
      spaceBetween: 0,
      slidesPerView: 6,
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      breakpoints: {
        320: {
          slidesPerView: 1,
          direction: 'vertical',
        },
        768: {
          slidesPerView: 6,
          spaceBetween: 0,
        },
      },
    });
    // eslint-disable-next-line no-unused-vars
    const swiperIndustries = new Swiper('.js-facts-slider', {
      spaceBetween: 30,
      slidesPerView: 1,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      thumbs: {
        swiper: swiperIndustriesTabs,
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
      },
    });

    const swiperPagination = new Swiper('.js-discover-slider-paging', {
      spaceBetween: 0,
      slidesPerView: 6,
      freeMode: true,
      watchSlidesProgress: true,
      breakpoints: {
        320: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 4,
        },
        1100: {
          slidesPerView: 5,
        },
      },
    });

    const  wee = document.querySelector('.wee').style;
    const navList = document.querySelectorAll('.swiper-slide.lite-item');
    const currentNav = document.querySelector('.lite-item.swiper-slide-active');
    wee['width'] = `${0}px`;
    if (currentNav) {
      wee['width'] = `${currentNav.offsetWidth}px`;
    }
    navList.forEach(function (nav, index) {
      nav.addEventListener('click', function () {
        wee['left'] = `${this.offsetLeft - currentNav.offsetLeft}px`;
        wee['width'] = `${this.offsetWidth}px`;
        swiperImage.slideTo(index);
        swiperDescription.slideTo(index);
      });
    });
    // eslint-disable-next-line no-unused-vars
    const swiperImage = new Swiper('.js-discover-slider', {
      spaceBetween: 30,
      slidesPerView: 1,
      allowTouchMove: false,
      thumbs: {
        swiper: swiperPagination,
      },
    });
    // eslint-disable-next-line no-unused-vars
    const swiperDescription = new Swiper('.js-discover-slider-nav', {
      fadeEffect: { crossFade: true },
      spaceBetween: 30,
      slidesPerView: 1,
      allowTouchMove: false,
      effect: 'fade',
    });
    swiperPagination.on('slideChange', function () {
      swiperDescription.slideTo(swiperPagination.activeIndex);
    });
  }
};

export default {
  init,
};
