import Accordion from './accordion';
import Animation from './animation';
import CountryDetect from './countryDetect';
import Header from './header';
import Reframe from './reframe';
import Swiper from './swiper';
// import Line from './magicLine'
import Tabs from './tabs';

export default [
  Reframe,
  Header,
  Swiper,
  // Line,
  Tabs,
  Accordion,
  Animation,
  CountryDetect,
];
