import ScrollReveal from 'scrollreveal';

const init = () => {
  window.addEventListener('load', () => {
    const sr = ScrollReveal();

    sr.reveal('.header__title img', { duration: 2000, distance: '120px' });
    sr.reveal('.header__title h1', { duration: 2000, distance: '130px', delay: 250 });
    sr.reveal('.header__body p', { duration: 2000, distance: '140px', delay: 500 });
    sr.reveal('.header__body div', { duration: 2000, distance: '150px', delay: 750 });
    sr.reveal('.tool', { duration: 2000, distance: '120px', delay: 1000 });
    sr.reveal('.discover__desc', { duration: 2250, delay: 250 });
    sr.reveal('.discover-slider', { duration: 2250, delay: 100 });

    if (window.innerWidth < 768) {
      sr.destroy();
    }
  });
};

export default {
  init,
};
